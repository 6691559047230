import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Container,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from '@mui/lab';
import { BlockTitle, PriceWrap, SummeryRow, TripBox } from './styled';
import { formatItalianNumber, getDate, getTime } from '../../utils/helper';
import { getTripDetail } from '../../redux/company';
import {
  resetPaymentLink,
  resetdownloadPDFFile,
} from '../../redux/company/slice';
import { resetClientConfirm } from '../../redux/email/slice';
import ChatInterface from '../../components/ClientChat';
import NextBestActionComponent from '../../components/NextBestActionComponent';

const TripDetail = () => {
  const { t } = useTranslation();
  const { tripRequestCode, busPartnerCode } = useParams();
  const {
    tripData,
    paymentLink,
    downloadTripPDFData,
    downloadTripPDFDataError,
  } = useSelector((state) => state.company);
  const { loading, clientConfirmDetail } = useSelector((state) => state.email);
  const [, setActiveStatus] = useState(-1);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    msg: '',
    type: 'success',
  });
  const downloadPDFURL = (url) => {
    if (url) {
      window.open(url, '_blank');
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isEmpty(tripRequestCode)) {
      dispatch(
        getTripDetail({
          trip_request_code: tripRequestCode,
          unique_code: busPartnerCode,
        })
      );
    }
  }, [tripRequestCode]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (
        document.visibilityState === 'visible' &&
        tripData?.request?.status !== 'offered'
      ) {
        window.location.reload();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      dispatch(resetClientConfirm());
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  useEffect(() => {
    if (!isEmpty(paymentLink) && paymentLink.url) {
      window.open(paymentLink.url, '_blank');
      dispatch(resetPaymentLink());
    }
  }, [paymentLink]);

  useEffect(() => {
    if (!isEmpty(downloadTripPDFData)) {
      downloadPDFURL(downloadTripPDFData?.data?.url);
      dispatch(resetdownloadPDFFile());
    }
    if (!isEmpty(downloadTripPDFDataError)) {
      setOpen(true);
      setAlertMsg({
        msg: downloadTripPDFDataError,
        type: 'error',
      });
    }
  }, [downloadTripPDFData, downloadTripPDFDataError]);
  useEffect(() => {
    if (clientConfirmDetail) {
      navigate(
        '/' + busPartnerCode + '/offer/' + tripRequestCode + '/conversation'
      );
    }
  }, [clientConfirmDetail]);

  return (
    <Container maxWidth={false}>
      <SummeryRow>
        <Grid container rowSpacing={1} className="summeryWrap">
          <Grid item xs={12} sm={12} md={6}>
            <NextBestActionComponent
              status={tripData?.request?.status}
              dispatch={dispatch}
              tripRequestCode={tripRequestCode}
              busPartnerCode={busPartnerCode}
              loading={loading}
              tripData={tripData}
            />
            <TripDetailSummary tripData={tripData} />
            <TripItinerary tripData={tripData} />
            {tripData?.request?.status !== 'offered' && (
              <>
                <BlockTitle>{t('TripDetail.YourDetails')}</BlockTitle>
                <TripCustomerDataNotOffered tripData={tripData} />
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ChatInterface />
          </Grid>
        </Grid>
      </SummeryRow>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={5000}
        onClose={() => setOpen(false)}
      >
        <Alert severity={alertMsg.type} sx={{ width: '100%' }}>
          {alertMsg.msg}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const TripDetailSummary = ({ tripData }) => {
  const { t } = useTranslation();
  return (
    <>
      <BlockTitle>{t('TripDetail.Summary')}</BlockTitle>
      <TripBox>
        <Grid container>
          <Grid item xs={12} sm={6} className="tripCol">
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              justifyContent="left"
              alignItems="left"
            >
              <PriceWrap>
                <Typography variant="h4">{t('TripDetail.Price')}</Typography>
                <Typography variant="h2">
                  {tripData?.request?.status !== 'created'
                    ? formatItalianNumber(tripData?.price?.price) + ' €'
                    : '-'}
                </Typography>
                {tripData?.request?.status !== 'created' && (
                  <Typography>
                    <span
                      style={{
                        textAlign: 'left',
                        fontSize: '12px',
                        fontWeight: '400',
                      }}
                    >
                      {tripData?.price?.include_vat
                        ? t('TripDetail.VATINCL', { vatper: 10 })
                        : t('TripDetail.VATEXCL', { vatper: 10 })}
                    </span>
                  </Typography>
                )}
              </PriceWrap>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} className="tripCol">
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              justifyContent="left"
              alignItems="left"
            >
              <PriceWrap>
                <Typography variant="h4" className="pl-0">
                  {t('TripDetail.RequestCode')}
                </Typography>
                <Typography variant="h2">
                  {tripData?.request?.trip_request_code}
                </Typography>
              </PriceWrap>
            </Stack>
          </Grid>
        </Grid>
      </TripBox>
    </>
  );
};

const TripItinerary = ({ tripData }) => {
  const { t } = useTranslation();

  return (
    <>
      <BlockTitle>{t('TripDetail.Itinerary')}</BlockTitle>
      <TripBox>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
          className="verticle_timeline_wrapper"
        >
          {!isEmpty(tripData) &&
            tripData?.customer_segments.map((step, index) => (
              <TimelineItem key={index} className="verticle_timeline">
                <TimelineSeparator>
                  <TimelineDot sx={{ background: '#4f46e5' }}>
                    {/* {index + 1} */}
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {/* tripData?.customer_segments[index - 1]['destination_stop']['arrival_datetime'] */}
                  {index !== 0 && (
                    <Typography>
                      {t('TripDetail.PointArrival', {
                        time: getTime(
                          tripData?.customer_segments[index - 1][
                            'destination_stop'
                          ]['arrival_datetime']
                        ),
                        date: getDate(
                          tripData?.customer_segments[index - 1][
                            'destination_stop'
                          ]['arrival_datetime']
                        ),
                      })}
                    </Typography>
                  )}

                  <Typography component="h3" className="steplabel">
                    {step?.departure_stop?.address}
                  </Typography>

                  <Typography>
                    {index === 0
                      ? t('TripDetail.StartDepature', {
                          time: getTime(
                            step?.departure_stop?.departure_datetime
                          ),
                          date: getDate(
                            step?.departure_stop?.departure_datetime
                          ),
                        })
                      : t('TripDetail.PointDepature', {
                          time: getTime(
                            step?.departure_stop?.departure_datetime
                          ),
                          date: getDate(
                            step?.departure_stop?.departure_datetime
                          ),
                        })}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          {!isEmpty(tripData) && tripData?.customer_segments && (
            <TimelineItem
              key={tripData?.customer_segments?.length + 1}
              className="verticle_timeline"
            >
              <TimelineSeparator>
                <TimelineDot sx={{ background: '#4f46e5' }}>
                  {/* {tripData?.customer_segments?.length + 1} */}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography component="h3" className="steplabel">
                  {
                    tripData?.customer_segments[
                      tripData?.customer_segments?.length - 1
                    ]['destination_stop']['address']
                  }
                </Typography>
                <Typography>
                  {t('TripDetail.EndArrival', {
                    time: getTime(
                      tripData?.customer_segments[
                        tripData?.customer_segments?.length - 1
                      ]['destination_stop']['arrival_datetime']
                    ),
                    date: getDate(
                      tripData?.customer_segments[
                        tripData?.customer_segments?.length - 1
                      ]['destination_stop']['arrival_datetime']
                    ),
                  })}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          )}
        </Timeline>
      </TripBox>
    </>
  );
};

const TripCustomerDataNotOffered = ({ tripData }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <TripBox>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <Typography>
              <span>{t('TripDetail.Name')}: </span>
              {tripData?.client?.client_name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <span>{t('TripDetail.Surname')}: </span>
              {tripData?.client?.client_surname}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <span>{t('TripDetail.Email')}: </span>
              {tripData?.client?.requestor_email}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <span>{t('TripDetail.Telephone')}: </span>
              {tripData?.client?.client_type == 'Company'
                ? tripData?.client?.company_number
                : tripData?.client?.client_number}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <span>{t('TripDetail.ClientType')}: </span>
              {t(`ClientTypes.${tripData?.client?.client_type}`)}
            </Typography>
          </Grid>
        </Grid>
      </TripBox>
      {tripData?.client?.client_type == 'Company' && (
        <TripBox>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={6} className="summeryField">
              <Typography>
                <span>{t('TripDetail.CompanyName')}: </span>
                {tripData?.client?.company_name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                <span>{t('TripDetail.VATNumber')}: </span>
                {tripData?.client?.company_vat_number}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} className="summeryField">
              <Typography>
                <span>{t('TripDetail.Address')}: </span>
                {tripData?.client?.company_address}
              </Typography>
            </Grid>
          </Grid>
        </TripBox>
      )}
      <TripBox>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6} className="summeryField">
            <Typography>
              <span>{t('TripDetail.NoPass')}: </span>
              {tripData?.request?.passenger_number}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className="summeryField">
            <Typography>
              <span>{t('TripDetail.EventType')}: </span>
              {tripData?.request?.event_type
                ? t(`EventTypes.${tripData?.request?.event_type}`)
                : ''}
            </Typography>
          </Grid>
          {tripData?.request?.additional_information && (
            <Grid item xs={12} md={12} className="summeryField">
              <Typography>
                <span>{t('TripDetail.AdditionInfo')}: </span>
                {tripData?.request?.additional_information}
              </Typography>
            </Grid>
          )}
        </Grid>
      </TripBox>
    </React.Fragment>
  );
};

export default TripDetail;
