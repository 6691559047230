import { createTheme, ThemeOptions } from '@mui/material/styles';

const theme: ThemeOptions = createTheme({
  typography: {
    fontFamily: 'League Spartan',
    h1: {
      fontFamily: 'League Spartan',
      fontSize: '25px',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'League Spartan',
      fontSize: '20px',
      fontWeight: 600,
    },
    h3: {
      fontFamily: 'League Spartan',
      fontSize: '18px',
      fontWeight: 600,
    },
    h4: {
      fontFamily: 'League Spartan',
      fontSize: '16px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '14px',
      fontFamily: 'League Spartan',
      fontWeight: 400,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: 'rgb(92, 91, 230)',
      light: '#e0e7ff',
      dark: '#4c4bd4',
    },
    secondary: {
      main: '#4242b1',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          boxShadow: 'none',
          borderRadius: '10px',
          fontStyle: 'normal',
          fontWeight: 600,
          textTransform: 'none',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: '0',
          border: '1px solid #e0e0e0',
          borderRadius: '5px',
          overflow: 'hidden',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          // borderBottom: '1px solid #e0e0e0',
          textAlign: 'center',
          fontSize: '1rem',
        },
        head: {
          fontWeight: 'bold',
          backgroundColor: '#f1f5f9',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: '#fcfdfe',
          },
          '&:last-child td': {
            borderBottom: 'none', // Remove border from the last row's cells
          },
        },
      },
    },
  },
});

export default theme;
