import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './app/app';
import './main.css';
import * as Sentry from '@sentry/react';

if (import.meta.env.VITE_ENVIRONMENT !== 'development') {
  Sentry.init({
    dsn: 'https://ee4dc22d867a291ca5f6c3f20f166701@o4508228469325824.ingest.de.sentry.io/4508439470145616',
    environment: import.meta.env.VITE_ENVIRONMENT || 'development',
  });
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
