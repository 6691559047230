import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { firebaseApp } from './firebase';
import { useTranslation } from 'react-i18next';

export const analytics = getAnalytics(firebaseApp);

export const useAnalyticsParams = () => {
  const location = useLocation();
  const { i18n } = useTranslation();

  return {
    page_path: location.pathname + location.search,
    bus_partner: location.pathname.split('/')[1] || '',
    user_lang: i18n.language,
  };
};

export const FirebaseAnalytics: React.FC = () => {
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (analytics) {
      const page_path = location.pathname + location.search;
      // Extract bus partner code from the first path segment
      const busPartnerCode = location.pathname.split('/')[1] || '';
      logEvent(analytics, 'page_view', {
        page_path,
        bus_partner: busPartnerCode,
        user_lang: i18n.language,
      });
    }
  }, [location, i18n.language]);

  return null;
};
