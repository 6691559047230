import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';
import { Title, MainBox, HeadingWithIcon } from './styled';

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <MainBox>
        <Title>
          <HeadingWithIcon>
            <Typography variant="h1" component="h1">
              {t('PageNotFound.Title')}
            </Typography>
          </HeadingWithIcon>
          <Typography variant="h1" component="p">
            {t('PageNotFound.Detail')}
          </Typography>
        </Title>
      </MainBox>
    </Container>
  );
};

export default PageNotFound;
