import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Map,
  useMapsLibrary,
  useMap,
  AdvancedMarker,
  APIProvider,
} from '@vis.gl/react-google-maps';
import { Box, Button, Skeleton } from '@mui/material';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import FlagIcon from '@mui/icons-material/Flag';
import PlaceIcon from '@mui/icons-material/Place';

interface ImportMetaEnv {
  readonly VITE_APP_GOOGLE: string;
  readonly VITE_APP_GOOGLE_MAPS_ID: string;
}

interface ImportMeta {
  readonly env: ImportMetaEnv;
}

interface TripStop {
  start: {
    address: string;
  };
  end: {
    address: string;
  };
}

interface MarkerProps {
  position: {
    lat: number;
    lng: number;
  };
  label: string;
  type: 'start' | 'end' | 'waypoint' | 'start_end';
}

interface DirectionsProps {
  directions: google.maps.DirectionsResult | null;
  onDirectionsReady?: () => void;
}

const createGoogleMapsUrl = (tripStops: TripStop[]) => {
  if (!tripStops?.length) return '';

  const params = new URLSearchParams({
    api: '1',
    origin: tripStops[0].start.address,
    destination: tripStops[tripStops.length - 1].end.address,
    travelmode: 'driving',
  });

  if (tripStops.length > 1) {
    const waypoints = tripStops.slice(0, -1).map((stop) => stop.end.address);
    if (waypoints.length) {
      params.append('waypoints', waypoints.join('|'));
    }
  }

  return `https://www.google.com/maps/dir/?${params.toString()}`;
};

export const MapComponent = ({
  tripStops,
  showOpenInGoogleMaps = false,
}: {
  tripStops: TripStop[];
  showOpenInGoogleMaps: boolean | undefined;
}) => {
  return (
    <APIProvider
      apiKey={import.meta.env.VITE_APP_GOOGLE}
      libraries={['places', 'maps', 'routes', 'core', 'marker']}
    >
      <MapComponentInner
        tripStops={tripStops}
        showOpenInGoogleMaps={showOpenInGoogleMaps}
      />
    </APIProvider>
  );
};

export const MapComponentInner = ({
  tripStops,
  showOpenInGoogleMaps = false,
}: {
  tripStops: TripStop[];
  showOpenInGoogleMaps: boolean;
}) => {
  const { t } = useTranslation();
  const [directionsLoaded, setDirectionsLoaded] = useState(false);
  const routesLibrary = useMapsLibrary('routes');
  const [directionsService, setDirectionsService] =
    useState<google.maps.DirectionsService | null>(null);
  const [directions, setDirections] =
    useState<google.maps.DirectionsResult | null>(null);

  useEffect(() => {
    console.log('Set routes library:', routesLibrary);

    if (!routesLibrary) return;
    setDirectionsService(new routesLibrary.DirectionsService());
  }, [routesLibrary]);

  // Use directions service
  useEffect(() => {
    if (!directionsService) return;

    console.log('Trip stops:', tripStops);

    if (tripStops == null || tripStops.length < 1) return;

    directionsService
      .route({
        origin: tripStops[0].start.address,
        destination: tripStops[tripStops.length - 1].end.address,
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: tripStops.slice(0, -1).map((stop) => ({
          location: stop.end.address,
          stopover: true,
        })),
        provideRouteAlternatives: false,
      })
      .then((response) => {
        console.log('Directions response:', response);
        setDirections(response);
        setDirectionsLoaded(true);
      })
      .catch((error) => {
        console.error('Error getting directions:', error);
        setDirectionsLoaded(true);
      });
  }, [directionsService, tripStops]);

  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
          height: '400px',
          width: '100%',
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <Map
          style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
          mapId={import.meta.env.VITE_APP_GOOGLE_MAPS_ID}
          defaultCenter={{ lat: 43.65, lng: -79.38 }}
          defaultZoom={9}
          mapTypeControl={false}
          streetViewControl={false}
          zoomControl={false}
          gestureHandling={'none'}
          fullscreenControl={false}
        >
          <TripStopsDirections
            directions={directions}
            onDirectionsReady={() => setDirectionsLoaded(true)}
          />
        </Map>
        {showOpenInGoogleMaps && (
          <Button
            sx={{ position: 'absolute', top: '10px', right: '10px' }}
            variant="contained"
            onClick={() => {
              window.open(createGoogleMapsUrl(tripStops), '_blank');
            }}
          >
            {t('General.OpenInGoogleMaps')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export const QuotabusMarker = ({ position, label, type }: MarkerProps) => {
  const getMarkerIcon = (type: MarkerProps['type']) => {
    switch (type) {
      case 'start':
        return <DirectionsBusIcon sx={{ fill: 'white' }} />;
      case 'end':
        return <FlagIcon sx={{ fill: 'white' }} />;
      case 'waypoint':
        return <PlaceIcon sx={{ fill: 'white' }} />;
      case 'start_end':
        return <DirectionsBusIcon sx={{ fill: 'white' }} />;
    }
  };

  const MarkerIcon = getMarkerIcon(type);

  return (
    <AdvancedMarker position={position} title={label}>
      <Box
        sx={{
          padding: '2px',
          background: type === 'waypoint' ? '#7675eb' : '#5C5BE6',
          borderColor: type === 'waypoint' ? '#5C5BE6' : '#5554ca',
          borderStyle: 'solid',
          borderRadius: '50% 50% 50% 0',
          transform: 'rotate(-45deg)',
          width: '36px',
          height: '36px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
        }}
      >
        <Box sx={{ transform: 'rotate(+45deg)' }}>{MarkerIcon}</Box>
      </Box>
    </AdvancedMarker>
  );
};

const TripStopsDirections = ({ directions }: DirectionsProps) => {
  const { t } = useTranslation();
  const map = useMap();
  const routesLibrary = useMapsLibrary('routes');
  const [directionsRenderer, setDirectionsRenderer] =
    useState<google.maps.DirectionsRenderer | null>();
  const legs = directions?.routes[0]?.legs;

  useEffect(() => {
    if (!map || !routesLibrary) return;
    setDirectionsRenderer(
      new routesLibrary.DirectionsRenderer({ map, suppressMarkers: true })
    );
  }, [map, routesLibrary]);

  useEffect(() => {
    console.log('Test:', directionsRenderer, map, directions);

    if (!directionsRenderer || !directions || !map) return;
    console.log('Directions renderer:', directionsRenderer);
    directionsRenderer.setDirections(directions);
    directionsRenderer.setRouteIndex(0);
    directionsRenderer.setMap(map);
  }, [routesLibrary, map, directions, directionsRenderer]);

  if (legs != null && legs.length > 0) {
    console.log(
      legs,
      legs[0].start_location == legs[legs.length - 1].end_location,
      legs[0].start_location,
      legs[legs.length - 1].end_location
    );
    const markers: MarkerProps[] = [];
    if (
      legs.length >= 1 &&
      legs[0].start_address == legs[legs.length - 1].end_address
    ) {
      markers.push({
        position: {
          lat: legs[0].start_location.lat(),
          lng: legs[0].start_location.lng(),
        },
        label: t('General.StartAndReturn'),
        type: 'start_end',
      });
      legs.slice(1).forEach((leg, index) => {
        markers.push({
          position: {
            lat: leg.start_location.lat(),
            lng: leg.start_location.lng(),
          },
          label: t('General.Stop', { number: index + 1 }),
          type: 'waypoint',
        });
      });
    } else {
      markers.push({
        position: {
          lat: legs[0].start_location.lat(),
          lng: legs[0].start_location.lng(),
        },
        label: t('General.Start'),
        type: 'start',
      });
      legs.forEach((leg, index) => {
        const type = index === legs.length - 1 ? 'end' : 'waypoint';
        const label =
          type === 'end'
            ? t('General.Arrival')
            : t('General.Stop', { number: index + 1 });
        markers.push({
          position: {
            lat: leg.end_location.lat(),
            lng: leg.end_location.lng(),
          },
          label,
          type,
        });
      });
    }

    return markers.map((marker, index) => (
      <QuotabusMarker
        key={index}
        position={marker.position}
        label={marker.label}
        type={marker.type}
      />
    ));
  }
  return null;
};
