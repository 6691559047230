import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '../redux/store';
import theme from '../styles/theme';
import RouterContainer from '../router';
import '../locales/i18n';
import './app.css';
import './firebase';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <RouterContainer />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
