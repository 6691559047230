/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { isEmpty, has } from 'lodash';
import { Turnstile } from '@marsidev/react-turnstile';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputAdornment,
  Link,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SouthIcon from '@mui/icons-material/South';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PeopleIcon from '@mui/icons-material/People';
import {
  TripInfo,
  AddStopButton,
  BottomInfo,
  BottomContentLeft,
  BottomContentRight,
  MainTripBox,
  CheckBoxSelect,
  Step1,
  Step2,
  HeadPart,
  PartnerFormWrapp,
  SubmitButton,
  FormBox,
  TermsConditions,
} from './styled';
import { Form, Formik } from 'formik';
import TripBoxComponent from '../../components/TripBoxComponent';
import ReturnTripBoxComponent from '../../components/TripBoxComponent/return';
import { convertTripData, getClientTypes } from '../../utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import { postPriceCalculation } from '../../redux/pricecalc';
import dayjs from 'dayjs';
import { resetPriceCalculation } from '../../redux/pricecalc/slice';
import { useNavigate, useParams } from 'react-router-dom';
import { MapComponent } from '@monorepo/components';
import { logEvent } from 'firebase/analytics';
import { analytics, useAnalyticsParams } from '../../app/analytics';

const API_KEY =
  // eslint-disable-next-line no-undef
  globalThis.GOOGLE_MAPS_API_KEY ?? import.meta.env.VITE_MAPS_API_KEY;

const TripMap = React.memo(
  ({ tripStops, returnStop, returnFlag }) => {
    const allStops = useMemo(() => {
      return returnFlag === 1 && returnStop != null
        ? [...tripStops, returnStop]
        : tripStops;
    }, [tripStops, returnStop, returnFlag]);

    return (
      <Box
        key="map"
        sx={{
          marginBottom: '16px',
          backgroundColor: '#eef2ff',
          padding: '4px',
          borderRadius: '10px',
        }}
      >
        <MapComponent
          mapId={import.meta.env.VITE_APP_GOOGLE_MAPS_ID}
          tripStops={allStops}
        />
      </Box>
    );
  },
  (prevProps, nextProps) => {
    // Only re-render if the coordinates or number of stops have changed
    const prevStops =
      prevProps.returnFlag === 1 && prevProps.returnStop != null
        ? [...prevProps.tripStops, prevProps.returnStop]
        : prevProps.tripStops;

    const nextStops =
      nextProps.returnFlag === 1 && nextProps.returnStop != null
        ? [...nextProps.tripStops, nextProps.returnStop]
        : nextProps.tripStops;

    if (prevStops.length !== nextStops.length) return false;

    return prevStops.every((stop, index) => {
      const nextStop = nextStops[index];
      return (
        stop.start?.lat === nextStop.start?.lat &&
        stop.start?.lon === nextStop.start?.lon &&
        stop.end?.lat === nextStop.end?.lat &&
        stop.end?.lon === nextStop.end?.lon
      );
    });
  }
);

const PriceCalculationPage = () => {
  const { busPartnerCode } = useParams();
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const [currentStep, setCurrentStep] = useState(1);
  const [isChecked, setIsChecked] = useState(true);
  const [divCount, setDivCount] = useState(1);
  const [failOpen, setFailOpen] = useState(false);
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = useState([]);
  const { loading, priceCalculationDetail, priceCalculationError } =
    useSelector((state) => state.pricecalc);
  const dispatch = useDispatch();
  const baseParams = useAnalyticsParams();

  useEffect(() => {
    if (priceCalculationError.error) {
      let errors = [];
      if (typeof priceCalculationError.message === 'string') {
        errors.push(priceCalculationError.message);
      } else {
        for (let key in priceCalculationError.message) {
          if (key.includes('trip_stops')) {
            for (let innerKey in priceCalculationError.message[key]) {
              for (let errorKey in priceCalculationError.message[key][
                innerKey
              ]) {
                errors.push(
                  `Sequence: ${parseInt(innerKey) + 1
                  } has error for ${errorKey}: ${priceCalculationError.message[key][innerKey][errorKey]
                  }`
                );
              }
            }
          } else if (key.includes('error')) {
            for (let msg in priceCalculationError.message.error) {
              errors.push(priceCalculationError.message.error[msg]);
            }
          } else if (key.includes('lng')) {
            errors.push(priceCalculationError?.message?.lng ?? 'Something');
          }
        }
      }
      setErrorMessages(errors);
      setFailOpen(true);
    }
  }, [priceCalculationError]);

  useEffect(() => {
    if (!isEmpty(priceCalculationDetail)) {
      navigate(`/${busPartnerCode}/request-received`);
    }
  }, [priceCalculationDetail]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFailOpen(false);
  };
  const addStop = (values, setFieldValue, insertAt) => {
    const newStop = {
      sequence: divCount + 1,
      date: !isEmpty(values.trip_stops[insertAt - 1]['next_depature'])
        ? dayjs(values.trip_stops[insertAt - 1]['next_depature'])
        : dayjs(values.trip_stops[insertAt - 1]['date']),
      time: !isEmpty(values.trip_stops[insertAt - 1]['next_depature'])
        ? dayjs(values.trip_stops[insertAt - 1]['next_depature'])
        : dayjs(values.trip_stops[insertAt - 1]['time']).add(1, 'hour'),
      start: { ...values.trip_stops[insertAt - 1].end },
      end: {
        lat: '',
        lon: '',
        address: '',
        post_code: '',
        city: '',
        country: '',
        region: '',
      },
      arrival: '',
      next_depature: '',
      duration: 0,
    };
    const updatedStops = [
      ...values.trip_stops.slice(0, insertAt),
      newStop,
      ...values.trip_stops.slice(insertAt),
    ];
    setFieldValue('trip_stops', updatedStops);
    setFieldValue('return_stop.start', {
      lat: '',
      lon: '',
      address: '',
      post_code: '',
      city: '',
      country: '',
      region: '',
      arrival: '',
      next_depature: '',
      duration: 0,
    });
    setFieldValue('return_stop.arrival', '');
    setFieldValue('return_stop.next_depature', '');
    setFieldValue('return_stop.duration', 0);
    setFieldValue(
      'return_stop.time',
      dayjs(values.return_stop.time).add(15, 'minutes')
    );
    // setFieldValue('trip_stops', [...values.trip_stops, newStop]);
    setDivCount(values.trip_stops.length + 1);
  };
  const addReturn = (values, setFieldValue) => {
    const newStop = {
      sequence: divCount + 1,
      date: dayjs(values.trip_stops[divCount - 1]['date']),
      time: dayjs(values.trip_stops[divCount - 1]['time']).add(1, 'hour'),
      start: values.trip_stops[divCount - 1].end,
      end: values.trip_stops[0].start,
    };
    setFieldValue('return_stop', newStop);
  };
  const handleDeleteStop = (values, setFieldValue, index) => {
    const updatedStops = [...values.trip_stops];
    // If the element to be deleted is the last one, store its end object
    if (index === updatedStops.length - 1 && values.return_flag) {
      const newReturn = {
        ...values.return_stop,
        start: updatedStops[index - 1].end,
      };
      setFieldValue('return_stop', newReturn, true);
    }
    // Delete Element from Array
    updatedStops.splice(index, 1);
    if (updatedStops.length > index) {
      //Assign Delete Previous end address to new index start.
      updatedStops[index].start = updatedStops[index - 1].end;
    }
    // Update sequence values
    const newUpdatedStops = updatedStops.map((item, i) => {
      return { ...item, sequence: i + 1 };
    });
    setFieldValue('trip_stops', newUpdatedStops);
    setDivCount(values.trip_stops.length - 1);
  };
  const initialValues = {
    return_flag: 1,
    bus_on_site_flag: 0,
    passenger_number: 49,
    trip_stops: [
      {
        sequence: 1,
        date: dayjs().add(4, 'weeks'),
        time: dayjs().add(4, 'weeks').set('hour', 9).startOf('hour'),
        start: {
          lat: '',
          lon: '',
          address: '',
          post_code: '',
          city: '',
          country: '',
          region: '',
        },
        end: {
          lat: '',
          lon: '',
          address: '',
          post_code: '',
          city: '',
          country: '',
          region: '',
        },
        arrival: '',
        next_depature: '',
        duration: 0,
      },
    ],
    return_stop: {
      sequence: 2,
      date: dayjs().add(4, 'weeks'),
      time: dayjs()
        .add(4, 'weeks')
        .set('hour', 9)
        .startOf('hour')
        .add(1, 'hour'),
      start: '',
      end: '',
      arrival: '',
      next_depature: '',
      duration: 0,
    },
    captcha_token: '',
    client_type: '',
    requestor_email: '',
    client_number: '',
    company_number: '',
    additional_information: '',
  };
  const validationSchema = Yup.object().shape({
    passenger_number: Yup.number()
      .required(t('PriceCalc.Validation.Required'))
      .min(8, t('PriceCalc.Validation.PassengersMin', { min: 8 }))
      .max(900, t('PriceCalc.Validation.PassengersMax', { max: 900 })),
    requestor_email: Yup.string()
      .email(t('PriceCalc.Validation.EmailNotValid'))
      .when('$currentStep', {
        is: 2,
        then: (schema) => schema.required(t('PriceCalc.Validation.Required')),
        otherwise: (schema) => schema,
      }),
    captcha_token: Yup.string().when('$currentStep', {
      is: 2,
      then: (schema) =>
        schema.required(t('PriceCalc.Validation.CaptchaRequired')),
      otherwise: (schema) => schema,
    }),
    client_type: Yup.string().when('$currentStep', {
      is: 2,
      then: (schema) =>
        schema.required(t('PriceCalc.Validation.ClientTypeRequired')),
      otherwise: (schema) => schema,
    }),
    company_number: Yup.number().when('client_type', {
      is: 'Company',
      then: () => Yup.number().required('Required'),
      otherwise: () => Yup.number().notRequired(),
    }),
    client_number: Yup.number().when('client_type', {
      is: 'Company',
      then: () => Yup.number().notRequired(),
      otherwise: () => Yup.number().required('Required'),
    }),
    trip_stops: Yup.array()
      .of(
        Yup.object().shape({
          sequence: Yup.number().required(t('PriceCalc.Validation.Required')),
          date: Yup.string().required(t('PriceCalc.Validation.Required')),
          time: Yup.string().required(t('PriceCalc.Validation.Required')),
          start: Yup.object().shape({
            lat: Yup.string().required(
              t('PriceCalc.Validation.DepatureNotValid')
            ),
            lon: Yup.string().required(
              t('PriceCalc.Validation.DepatureNotValid')
            ),
            address: Yup.string().nullable(),
            post_code: Yup.string().nullable(),
            city: Yup.string().nullable(),
            country: Yup.string().nullable(),
            region: Yup.string().nullable(),
          }),
          end: Yup.object().shape({
            lat: Yup.string().required(
              t('PriceCalc.Validation.DestinationNotValid')
            ),
            lon: Yup.string().required(
              t('PriceCalc.Validation.DestinationNotValid')
            ),
            address: Yup.string().nullable(),
            post_code: Yup.string().nullable(),
            city: Yup.string().nullable(),
            country: Yup.string().nullable(),
            region: Yup.string().nullable(),
          }),
        })
      )
      .required(t('PriceCalc.Validation.Required')),
  });
  const handleUpdateConfig = (values) => {
    handleClose();
    values[values.client_type === 'Company' ? 'client_number' : 'company_number'] = '';
    const payload = {
      ...values,
      unique_code: busPartnerCode,
      lng: lang.toUpperCase(),
      client_number: values.client_number?.toString() || '',
      company_number: values.company_number?.toString() || '',
    };
    if (payload.return_flag) {
      payload.trip_stops = [...payload.trip_stops, payload.return_stop];
    }
    delete payload?.return_stop;
    payload.trip_stops = convertTripData(payload.trip_stops, true);
    dispatch(postPriceCalculation(payload));
    logEvent(analytics, 'get_offer', {
      ...baseParams,
    });
  };
  const handleSwitchtoStep2 = (errors, submitForm, values) => {
    if (isStep1Valid(values)) {
      logEvent(analytics, 'purchase_continue', {
        ...baseParams,
      });
      setCurrentStep(2);
    }
  };

  const isStep1Valid = (values) => {
    return values.trip_stops.every(
      (stop) => stop.start.lat && stop.start.lon && stop.end.lat && stop.end.lon
    );
  };

  return (
    <Container maxWidth="lg">
      <FormBox>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleUpdateConfig}
          validateOnChange={true}
          validateOnBlur={true}
          validationContext={{ currentStep }}
        >
          {({
            values,
            touched,
            errors,
            setFieldValue,
            handleChange,
            handleSubmit,
            handleBlur,
            submitForm,
          }) => (
            <Form noValidate onSubmit={handleSubmit} autoComplete="off">
              {currentStep === 1 && (
                <Step1>
                  <Stack direction="row" spacing={2}>
                    <CheckBoxSelect>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <input
                              name="return_flag"
                              type="radio"
                              value="1"
                              checked={values.return_flag === 1}
                              onChange={(e) => {
                                handleChange(e);

                                logEvent(analytics, 'set_return_flag', {
                                  ...baseParams,
                                  flag_value: 1,
                                });

                                const updatedValue = e.target.value;
                                if (updatedValue == 1) {
                                  setIsChecked(true);
                                  addReturn(values, setFieldValue);
                                } else {
                                  setFieldValue('return_stop', {});
                                  setIsChecked(false);
                                }
                                setFieldValue(
                                  'return_flag',
                                  parseInt(updatedValue)
                                );
                              }}
                              onBlur={handleBlur}
                            />
                          }
                          label={t('PriceCalc.RoundTrip')}
                        />
                      </FormGroup>
                    </CheckBoxSelect>
                    <CheckBoxSelect>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <input
                              name="return_flag"
                              type="radio"
                              value="0"
                              checked={values.return_flag === 0}
                              onChange={(e) => {
                                logEvent(analytics, 'set_return_flag', {
                                  ...baseParams,
                                  flag_value: 0,
                                });
                                handleChange(e);
                                const updatedValue = e.target.value;
                                if (updatedValue == 1) {
                                  setIsChecked(true);
                                  addReturn(values, setFieldValue);
                                } else {
                                  setFieldValue('return_stop', {});
                                  setIsChecked(false);
                                }
                                setFieldValue(
                                  'return_flag',
                                  parseInt(updatedValue)
                                );
                              }}
                              onBlur={handleBlur}
                            />
                          }
                          label={t('PriceCalc.OneWay')}
                        />
                      </FormGroup>
                    </CheckBoxSelect>
                  </Stack>
                  <TripInfo>
                    {values.trip_stops.map((_, index) => (
                      <MainTripBox key={index}>
                        <TripBoxComponent
                          index={index}
                          deleteStop={() => {
                            handleDeleteStop(values, setFieldValue, index);
                            logEvent(analytics, 'delete_stop', {
                              ...baseParams,
                              stop_number: index,
                            });
                          }}
                        />
                        <AddStopButton className="button">
                          <Button
                            variant="contained"
                            onClick={() => {
                              addStop(values, setFieldValue, index + 1);
                              logEvent(analytics, 'add_stop', {
                                ...baseParams,
                                stop_number: index + 1,
                              });
                            }}
                          >
                            {t('PriceCalc.AddStop')}
                          </Button>
                        </AddStopButton>
                      </MainTripBox>
                    ))}
                    {/* {console.log(isChecked)} */}
                    {isChecked && divCount > 0 && (
                      <ReturnTripBoxComponent
                        index={divCount}
                        returnBlock={true}
                      />
                    )}
                  </TripInfo>
                  <BottomInfo>
                    <BottomContentLeft>
                      <CheckBoxSelect>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <input
                                name="bus_on_site_flag"
                                type="checkbox"
                                value="1"
                                checked={values.bus_on_site_flag === 1}
                                onChange={(e) => {
                                  logEvent(analytics, 'bus_on_site_flag', {
                                    ...baseParams,
                                    bus_on_site_flag: values.bus_on_site_flag,
                                  });
                                  handleChange(e);
                                  const updatedValue = e.target.checked ? 1 : 0;
                                  handleChange({
                                    target: {
                                      name: 'bus_on_site_flag',
                                      value: updatedValue,
                                    },
                                  });
                                }}
                                onBlur={handleBlur}
                              />
                            }
                            label={t('PriceCalc.BusOnSite')}
                          />
                        </FormGroup>
                      </CheckBoxSelect>
                    </BottomContentLeft>
                    <Box
                      sx={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        loading={loading}
                        endIcon={<ArrowCircleRightOutlinedIcon />}
                        onClick={() =>
                          handleSwitchtoStep2(errors, submitForm, values)
                        }
                        variant="contained"
                        disabled={!isStep1Valid(values) || loading}
                      >
                        {t('PriceCalc.Continue')}
                      </Button>
                    </Box>
                  </BottomInfo>
                </Step1>
              )}
              {currentStep === 2 && (
                <Step2>
                  <HeadPart>
                    <Button
                      variant="contained"
                      onClick={() => setCurrentStep(1)}
                    >
                      <ArrowCircleLeftOutlinedIcon />
                      {t('PriceCalc.EditItinerary')}
                    </Button>
                  </HeadPart>
                  <Box className="titleStyle">
                    <PartnerFormWrapp>
                      <Grid container spacing={6} sx={{ mt: 1 }}>
                        <Grid item xs={12} md={6}>
                          <Box className="recapInfo">
                            <Typography
                              variant="h5"
                              sx={{
                                fontWeight: 'bold',
                                marginBottom: '10px',
                                fontSize: '20px',
                              }}
                            >
                              {t('Itinerario')}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                marginBottom: '10px',
                                fontSize: '14px',
                                color: '#4f4f4f',
                              }}
                            >
                              {t('PriceCalc.NoteAddresses')}
                            </Typography>
                            <TripMap
                              tripStops={values.trip_stops}
                              returnStop={values.return_stop}
                              returnFlag={values.return_flag}
                            />
                            {values.trip_stops.map((stop, index) => (
                              <Box
                                key={index}
                                sx={{
                                  marginBottom: '16px',
                                  backgroundColor: '#eef2ff',
                                  padding: '20px 25px',
                                  borderRadius: '10px',
                                }}
                              >
                                {/* Start Address */}
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: 600,
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <FiberManualRecordIcon
                                    fontSize="small"
                                    sx={{
                                      marginRight: '8px',
                                      color: '#4f46e5',
                                    }}
                                  />
                                  {stop.start?.address ||
                                    t('PriceCalc.NotAvailable')}
                                </Typography>

                                {/* Departure Date and Time */}
                                <Typography
                                  variant="caption"
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <SouthIcon
                                    fontSize="small"
                                    sx={{
                                      marginRight: '8px',
                                      color: '#4f46e5',
                                    }}
                                  />
                                  {stop.date && stop.time
                                    ? `${dayjs(stop.date).format(
                                      'DD/MM/YYYY'
                                    )} alle ${dayjs(stop.time).format(
                                      'HH:mm'
                                    )}`
                                    : t('PriceCalc.NotAvailable')}
                                </Typography>

                                {/* End Address */}
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: 600,
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '8px',
                                  }}
                                >
                                  <FiberManualRecordIcon
                                    fontSize="small"
                                    sx={{
                                      marginRight: '8px',
                                      color: '#4f46e5',
                                    }}
                                  />
                                  {stop.end?.address ||
                                    t('PriceCalc.NotAvailable')}
                                </Typography>

                                {/* Arrival Date and Time */}
                                <Typography
                                  variant="caption"
                                  sx={{ marginLeft: '28px' }}
                                >
                                  {stop.arrival
                                    ? `${dayjs(stop.arrival).format(
                                      'DD/MM/YYYY'
                                    )} alle ${dayjs(stop.arrival).format(
                                      'HH:mm'
                                    )}`
                                    : t('PriceCalc.NotAvailable')}
                                </Typography>
                              </Box>
                            ))}
                            {values.return_flag === 1 && values.return_stop && (
                              <Box
                                sx={{
                                  marginBottom: '16px',
                                  backgroundColor: '#eef2ff',
                                  padding: '20px 25px',
                                  borderRadius: '10px',
                                }}
                              >
                                {/* Start Address */}
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: 600,
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <FiberManualRecordIcon
                                    fontSize="small"
                                    sx={{
                                      marginRight: '8px',
                                      color: '#4f46e5',
                                    }}
                                  />
                                  {values.return_stop.start?.address ||
                                    t('PriceCalc.NotAvailable')}
                                </Typography>

                                {/* Departure Date and Time */}
                                <Typography
                                  variant="caption"
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <SouthIcon
                                    fontSize="small"
                                    sx={{
                                      marginRight: '8px',
                                      color: '#4f46e5',
                                    }}
                                  />
                                  {values.return_stop.date &&
                                    values.return_stop.time
                                    ? `${dayjs(values.return_stop.date).format(
                                      'DD/MM/YYYY'
                                    )} alle ${dayjs(
                                      values.return_stop.time
                                    ).format('HH:mm')}`
                                    : t('PriceCalc.NotAvailable')}
                                </Typography>

                                {/* End Address */}
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: 600,
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '8px',
                                  }}
                                >
                                  <FiberManualRecordIcon
                                    fontSize="small"
                                    sx={{
                                      marginRight: '8px',
                                      color: '#4f46e5',
                                    }}
                                  />
                                  {values.return_stop.end?.address ||
                                    t('PriceCalc.NotAvailable')}
                                </Typography>

                                {/* Arrival Date and Time */}
                                <Typography
                                  variant="caption"
                                  sx={{ marginLeft: '28px' }}
                                >
                                  {values.return_stop.arrival
                                    ? `${dayjs(
                                      values.return_stop.arrival
                                    ).format('DD/MM/YYYY')} alle ${dayjs(
                                      values.return_stop.arrival
                                    ).format('HH:mm')}`
                                    : t('PriceCalc.NotAvailable')}
                                </Typography>
                              </Box>
                            )}
                            <Typography
                              variant="body1"
                              sx={{ marginBottom: '16px' }}
                            >
                              {t('PriceCalc.BusOnSite')}:{' '}
                              <strong>
                                {values.bus_on_site_flag === 1
                                  ? t('PriceCalc.Yes')
                                  : t('PriceCalc.No')}
                              </strong>
                            </Typography>
                          </Box>
                        </Grid>

                        {/* Right Column: Form */}
                        <Grid item xs={12} md={6}>
                          <Box>
                            <Typography
                              sx={{
                                fontSize: '20px',
                                fontWeight: '700',
                                marginBottom: '10px',
                              }}
                            >
                              {t('PriceCalc.InsertData')}
                            </Typography>
                            <FormGroup row className="formWrapp">
                              <FormControl
                                component="fieldset"
                                sx={{ width: '100%' }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  sx={{ marginBottom: '8px' }}
                                >
                                  {t('PriceCalc.ClientType')}
                                </Typography>
                                <RadioGroup
                                  name="client_type"
                                  value={values.client_type}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  {getClientTypes().map((name) => (
                                    <FormControlLabel
                                      key={name}
                                      value={name}
                                      control={<Radio />}
                                      label={t(`ClientTypes.${name}`)}
                                    />
                                  ))}
                                </RadioGroup>
                                {touched.client_type && errors.client_type && (
                                  <FormHelperText error>
                                    {errors.client_type}
                                  </FormHelperText>
                                )}
                              </FormControl>
                              <TextField
                                sx={{ width: '100%' }}
                                placeholder={t('PriceCalc.Email')}
                                error={
                                  touched.requestor_email &&
                                  errors.requestor_email
                                }
                                helperText={
                                  touched.requestor_email &&
                                  errors.requestor_email
                                }
                                type="email"
                                name="requestor_email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.requestor_email}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <MailOutlineIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {values.client_type === 'Company' ? (
                                <TextField
                                  sx={{ width: '100%' }}
                                  placeholder={t('TripDetail.Telephone')}
                                  error={
                                    !!(touched.company_number && errors.company_number)
                                  }
                                  helperText={
                                    touched.company_number && errors.company_number
                                  }
                                  type="number"
                                  name="company_number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.company_number}
                                  onKeyDown={(e) => {
                                    if (['e', 'E', '-'].includes(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <LocalPhoneOutlinedIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              ) : (
                                <TextField
                                  sx={{ width: '100%' }}
                                  placeholder={t('TripDetail.Telephone')}
                                  error={
                                    !!(touched.client_number && errors.client_number)
                                  }
                                  helperText={
                                    touched.client_number && errors.client_number
                                  }
                                  type="number"
                                  name="client_number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.client_number}
                                  onKeyDown={(e) => {
                                    if (['e', 'E', '-'].includes(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <LocalPhoneOutlinedIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                              <TextField
                                sx={{ width: '100%' }}
                                placeholder={t('PriceCalc.Passengers')}
                                error={
                                  touched.passenger_number &&
                                  errors.passenger_number
                                }
                                helperText={
                                  touched.passenger_number &&
                                  errors.passenger_number
                                }
                                inputProps={{ min: 8, max: 900 }}
                                type="number"
                                name="passenger_number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === 'e' ||
                                    e.key === 'E' ||
                                    e.key === '-' ||
                                    e.key === '+'
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                value={values?.passenger_number}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <PeopleIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormGroup>
                            <FormGroup>
                              <TextareaAutosize
                                style={{
                                  padding: '10px 15px',
                                  border: '1px solid #ccc',
                                  borderRadius: '5px',
                                  fontFamily: 'League Spartan',
                                  fontSize: '16px',
                                  lineHeight: '1.5',
                                  color: '#000',
                                  backgroundColor: '#fff',
                                  marginBottom: '20px',
                                }}
                                name="additional_information"
                                value={values.additional_information}
                                aria-label="additional_information"
                                minRows={6}
                                placeholder={t('PriceCalc.AdditionalInfo')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </FormGroup>
                            <FormGroup
                              style={{
                                marginBottom: '20px',
                              }}
                            >
                              <Turnstile
                                as="aside"
                                options={{
                                  size: 'flexible',
                                  language: lang,
                                }}
                                className="float-right"
                                siteKey={
                                  import.meta.env
                                    .VITE_APP_CFT_SITEKEY_TRIPREQUEST
                                }
                                onSuccess={(token) => {
                                  console.log(token);
                                  setFieldValue('captcha_token', token);
                                }}
                              />
                              {touched['captcha_token'] &&
                                errors['captcha_token'] && (
                                  <FormHelperText error>
                                    {errors['captcha_token']}
                                  </FormHelperText>
                                )}
                            </FormGroup>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginBottom: '20px',
                              }}
                            >
                              <Button
                                loading={loading}
                                type="submit"
                                variant="contained"
                                endIcon={<ArrowCircleRightOutlinedIcon />}
                                disabled={
                                  loading ||
                                  !values.client_type ||
                                  !values.requestor_email ||
                                  !values['captcha_token'] ||
                                  errors.requestor_email
                                }
                              >
                                {t('PriceCalc.OfferEmail')}
                              </Button>
                            </Box>
                            <TermsConditions>
                              <Typography sx={{ textAlign: 'right' }}>
                                {t('PriceCalc.AcceptPPTC')
                                  .split('[PrivacyPolicy]')
                                  .map((part, index) =>
                                    index !== 0 ? (
                                      <React.Fragment key={index}>
                                        <Link
                                          target="_blank"
                                          href="privacy-policy"
                                          color="primary"
                                          rel="noopener noreferrer"
                                        >
                                          {t('PriceCalc.PrivacyPolicy')}
                                        </Link>
                                        {part
                                          .split('[TermsConditions]')
                                          .map((part2, index2) =>
                                            index2 !== 0 ? (
                                              <React.Fragment key={index2}>
                                                <Link
                                                  target="_blank"
                                                  href="terms-conditions"
                                                  color="primary"
                                                  rel="noopener noreferrer"
                                                >
                                                  {t(
                                                    'PriceCalc.TermsConditions'
                                                  )}
                                                </Link>
                                                {part2}
                                              </React.Fragment>
                                            ) : (
                                              part2
                                            )
                                          )}
                                      </React.Fragment>
                                    ) : (
                                      part
                                    )
                                  )}
                              </Typography>
                            </TermsConditions>
                          </Box>
                        </Grid>
                      </Grid>
                    </PartnerFormWrapp>
                  </Box>
                </Step2>
              )}
            </Form>
          )}
        </Formik>
      </FormBox>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={failOpen}
        onClose={handleClose}
      >
        {
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {!isEmpty(errorMessages) &&
              errorMessages.map((error, index) => (
                <Typography key={index}>{error}</Typography>
              ))}
          </Alert>
        }
      </Snackbar>
    </Container>
  );
};

export default PriceCalculationPage;
