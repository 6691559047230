import { initializeApp } from 'firebase/app';

const firebaseConfig = JSON.parse(import.meta.env.VITE_APP_FIREBASE_CONFIG);

const firebaseOptions = {
  ...firebaseConfig,
  automaticDataCollectionEnabled: true,
};

const firebaseApp = initializeApp(firebaseOptions);

export { firebaseApp };
