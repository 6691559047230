import React, { useEffect } from 'react';
import HeaderComponent from '../HeaderComponent';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentBox, CustomContainer } from './styled';
import { resetCompany } from '../../redux/company/slice';
import { getBusPartnerDetail } from '../../redux/company';
import logo from '../../assets/icon/logo.svg';

const LayoutComponent = ({ children }) => {
  const { t } = useTranslation();
  const { busPartnerCode } = useParams();
  const { companyError } = useSelector((state) => state.company);
  const label = (
    <span>
      <a href="https://quotabus.com/" target="_blank" rel="noopener noreferrer">
        <img
          src={logo}
          alt="Quotabus"
          style={{ width: 'auto', height: '20px' }}
        />
      </a>
    </span>
  );

  const handleClick = () => {
    window.open('https://quotabus.com/', '_blank', 'noopener,noreferrer');
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // This is used to change the name of the company in the header
  useEffect(() => {
    if (busPartnerCode) {
      dispatch(getBusPartnerDetail({ unique_code: busPartnerCode }));
    }
  }, [busPartnerCode, dispatch]);

  useEffect(() => {
    if (companyError) {
      navigate('/');
    }
    return () => {
      dispatch(resetCompany());
    };
  }, [companyError, dispatch, navigate]);

  return (
    <>
      <HeaderComponent />
      <CustomContainer>
        <ContentBox>{children}</ContentBox>
        <div
          className="footer-part"
          style={{ cursor: 'pointer', margin: '100px 0' }}>
          <p
            style={{
              lineHeight: '1.2',
              marginBottom: '8px',
              color: '#1e293b',
            }}>
            {t('Footer.PoweredBy')}{' '}
          </p>
          <Chip
            label={label}
            color="primary"
            clickable
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
            variant="outlined"
          />
        </div>
      </CustomContainer>
    </>
  );
};

export default LayoutComponent;
