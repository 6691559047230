import styled from '@emotion/styled';
import { Button, ButtonProps, SxProps } from '@mui/material';
import { ArrowBack, SvgIconComponent } from '@mui/icons-material';
import { Theme } from '@emotion/react';
import React from 'react';

const StyledBackButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '& .MuiButton-contained': {
    background: 'transparent',
    color: (theme as any).palette.primary.main,
    boxShadow: 'none',
    fontSize: '0.925rem',
    fontWeight: 600,
    paddingLeft: '4px',
    paddingRight: '4px',
    '&:hover': {
      background: 'transparent',
      boxShadow: 'none',
    },
    '& svg': {
      marginRight: '5px',
      width: '20px',
      height: '20px',
    },
  },
}));

export const BackButton = ({
  onClick,
  text,
  sx,
  icon,
}: {
  onClick: () => void;
  text: string;
  sx?: SxProps<Theme>;
  icon?: React.ReactNode;
}) => {
  return (
    <StyledBackButton>
      <Button
        startIcon={icon || <ArrowBack />}
        variant="contained"
        onClick={onClick}
        sx={{ ...sx }}
      >
        {text}
      </Button>
    </StyledBackButton>
  );
};
