import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckmarkIcon } from '../../assets/icon/index';
import { resetAcceptRequest } from '../../redux/email/slice';
import { acceptRequest } from '../../redux/email/index';
import { changeLanguage } from '../../utils/helper';
import {
  Title,
  MainBox,
  CheckMark,
  HeadingWithIcon,
  CircluarBox,
} from './styled';

const BusPartnerAcceptRequest = () => {
  const { t } = useTranslation();
  const { loading, acceptRequestDetail, acceptRequestError } = useSelector(
    (state) => state.email
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requestId } = useParams();

  useEffect(() => {
    changeLanguage('it'); // For now, bus partners are all Italians
    if (requestId) {
      dispatch(acceptRequest({ requestId }));
    }
    return () => {
      dispatch(resetAcceptRequest());
    };
  }, [dispatch, requestId]);

  useEffect(() => {
    if (acceptRequestError) {
      navigate('/pagenotfound');
    }
  }, [acceptRequestError, navigate]);

  // New useEffect to redirect to home if successful
  useEffect(() => {
    if (acceptRequestDetail) {
      // Optionally, display the success message for a few seconds before redirecting
      const timer = setTimeout(() => {
        // eslint-disable-next-line no-undef
        const partnerPortalUrl = import.meta.env.VITE_APP_PARTNER_PORTAL_URL;
        window.location.replace(
          `${partnerPortalUrl}/request/${requestId}/conversation`
        );
      }, 3000); // Redirect after 3 seconds
      return () => clearTimeout(timer); // Clear the timer on unmount
    }
  }, [acceptRequestDetail, navigate]);

  return (
    <React.Fragment>
      <Container>
        {acceptRequestDetail && (
          <MainBox>
            <Title>
              <HeadingWithIcon>
                <CheckMark>
                  <CheckmarkIcon />
                </CheckMark>
                <Typography variant="h1" component="h1">
                  {t('BusPartnerAcceptRequest.Title')}
                </Typography>
              </HeadingWithIcon>
              <Typography variant="h1" component="p">
                {t('BusPartnerAcceptRequest.Detail')}
              </Typography>
            </Title>
          </MainBox>
        )}
        {loading && (
          <CircluarBox>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          </CircluarBox>
        )}
      </Container>
    </React.Fragment>
  );
};

export default BusPartnerAcceptRequest;
