import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CrossIcon } from '../../assets/icon/index';
import { resetRejectRequest } from '../../redux/email/slice';
import { rejectRequest } from '../../redux/email/index';
import { changeLanguage } from '../../utils/helper';
import {
  Title,
  MainBox,
  CheckMarkRed,
  HeadingWithIcon,
  CircluarBox,
} from './styled';

const BusPartnerRejectRequest = () => {
  const { t } = useTranslation();
  const { loading, rejectRequestDetail, rejectRequestError } = useSelector(
    (state) => state.email
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requestId } = useParams();
  useEffect(() => {
    changeLanguage('it'); // for now bus partners are all italians
    if (requestId) {
      dispatch(rejectRequest({ requestId }));
    }
    return () => {
      dispatch(resetRejectRequest());
    };
  }, [requestId]);

  useEffect(() => {
    if (rejectRequestError) {
      navigate('/pagenotfound');
    }
  }, [rejectRequestError]);
  return (
    <Container>
      {loading && (
        <CircluarBox>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        </CircluarBox>
      )}
      {rejectRequestDetail && (
        <MainBox>
          <Title>
            <HeadingWithIcon>
              <CheckMarkRed>
                <CrossIcon />
              </CheckMarkRed>
              <Typography variant="h1" component="h1">
                {t('BusPartnerRejectRequest.Title')}
              </Typography>
            </HeadingWithIcon>
            <Typography variant="h1" component="p">
              {t('BusPartnerRejectRequest.Detail')}
            </Typography>
          </Title>
        </MainBox>
      )}
    </Container>
  );
};

export default BusPartnerRejectRequest;
