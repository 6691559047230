import React, { useEffect } from 'react';
import { toogleLanguage, lang } from '../../locales/i18n';
import { useSelector } from 'react-redux';
import { Typography, AppBar, Select, MenuItem, Toolbar } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { SwitchLanguage } from './styled';
import BusIcon from '../../assets/icon/bus-profile.svg';

const languages = [
  { code: 'it', name: 'Italiano', flag: '🇮🇹' },
  { code: 'en', name: 'English', flag: '🇬🇧' },
  { code: 'fr', name: 'Français', flag: '🇫🇷' },
  { code: 'de', name: 'Deutsch', flag: '🇩🇪' },
  { code: 'es', name: 'Español', flag: '🇪🇸' },
];

const HeaderComponent = () => {
  const { company } = useSelector((state) => state.company);
  const handleChangeLanguage = (event) => {
    const selectedLang = event.target.value;
    if (selectedLang !== lang()) {
      toogleLanguage(selectedLang);
      document.documentElement.lang = selectedLang;
    }
  };
  useEffect(() => {
    document.documentElement.lang = lang();
  }, []);
  return (
    <AppBar
      component="nav"
      color="transparent"
      position="static"
      elevation={0}
      sx={{
        borderBottom: '1px solid #cbd5e1', // Adding bottom border
      }}
    >
      <Toolbar>
        <div
          style={{
            backgroundColor: '#e0e7ff',
            padding: '4px',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
          }}
        >
          <img src={BusIcon} style={{ padding: '3px' }} alt="Bus" />
        </div>
        <Typography
          className="headerTitle"
          component="div"
          variant="h6"
          sx={{
            mt: 1.5,
            mb: 1,
            mx: 1,
            fontSize: '20px',
            fontWeight: '600',
            color: '#1e293b',
            flexGrow: 1,
          }}
        >
          {company?.name || 'Quotabus'}
        </Typography>
        <SwitchLanguage>
          <Select
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            value={lang()}
            onChange={handleChangeLanguage}
            className="flag_dropdown"
            IconComponent={() => <ArrowDropDownIcon className="flagIcon" />}
          >
            {languages.map(({ code, name, flag }) => (
              <MenuItem key={code} value={code} className="flag_icon">
                <Typography component="p">
                  {flag} {code.toLocaleUpperCase()}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </SwitchLanguage>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderComponent;
